import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://player.vimeo.com/video/402225592"
    bibleGroupSrc="https://www.docdroid.net/d49zPTZ/bible-group-homework-3-29.pdf"
    bibleGroupText="Bible Group Homework"
    notesSrc="http://bible.com/events/7154540"
    notesText="Sermon Notes"
  >
    <SEO title="We Get a New Perspective - When People Pray" />
  </Layout>
)

export default SermonPost
